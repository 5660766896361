.FAQ{
	/* background-color: #1E3D59; */
	position: relative;
	color: white;
	text-align: center;
	padding: 50px 0px 100px 0px;
	font-size: 16px;
    /* padding: 100px 45vw; */
}

.accordion-faq {
	cursor: pointer;
}

.blue-bg-faq {
	width: 100%;
	height: 400px;
	z-index: -999;
	border-bottom-left-radius: 30%;
	border-bottom-right-radius: 30%;
	position: absolute;
	top: 0;
	background-color: var(--primary);
}

.info-FAQ{
    background-color: #1E3D59;
	color: white;
	padding: 20px 0px 0px 0px;
}

.tombol{
	background-color: #FFAE00;
	color: white;
	border-radius: 5px;
    margin-top: 10px;
}

.font{
    font-size: 24px;
}

.card-header,.card-body{
	text-align: left;
	color: black;
}

@media (max-width: 768px) {
	.blue-bg-faq {
		border-bottom-left-radius: 0%;
		border-bottom-right-radius: 0%;
	}
}