.container-banyak .card-footer{
    background-color: #1E3D59 ;
    color: white;
    font-weight:500;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
  } 

  .card-footer-block {
    background-color: white;
    z-index: 10;
    position: absolute;
    bottom: 0;
    transform: translateY(99%);
    width: 100%;
    height: 75px;
  }

  .seni-card:not(.card-footer):hover .card-footer {
    transition: 0.5s;
    transform: translateY(0);
  }
  
  /* .container-banyak .card-body{
    width: 300px;
    background-color: #1E3D59 ;
    /* color: white; */
    /* font-weight: 500px; */
  /* } */ 
  
  /* .container-banyak .card-img-top{
   position: relative;
  } */
  /* .container-banyak .card-title  {
    color: #1E3D59;
    font-weight: bold;
  } */

  .container-banyak .nalar-card{
    width: 300px;
    height: 40vh;
    /* background-color: brown; */
    margin: 5px;
    text-align: center;
    margin-bottom: 100px;
    text-decoration:none;
    border: white;
  }

  .container-banyak .olahraga-card{
    width: 300px;
    height: 40vh;
    /* background-color: brown; */
    margin: 5px;
    text-align: center;
    margin-bottom: 100px;
    text-decoration:none;
    border: white;
  }

  .container-banyak .seni-card{
    position: relative;
    width: 300px;
    /* height: 40vh; */
    height: 300px;
    /* background-color: brown; */
    margin: 5px;
    text-align: center;
    margin-bottom: 100px;
    text-decoration:none;
    border: white;
  }
  
  .container-banyak{
    width: 80%;
    height: 100%;
    border: 0px solid black;
    margin: 20px auto;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

  }
  /* .row{
    margin-left: 200px;
    margin-right: 200px;
    margin-top : 40px;
    margin-bottom : 40px;
    position: relative;
  }
  
  .col{
    padding: 30px;
    margin-bottom: 60px;
  } */
  /* .heading-app {
    font-weight: bold;
    color: #1E3D59;
  } */
  
  .kategori-block{
    border: 30px solid #FFD987;
    background-color:#FFD987 ;
    color: #1E3D59;
    text-align: center;
    font-size:x-large;
    font-weight: bold;
  
  }

  .kategori-header {
    letter-spacing: 10px;
  }

  .ukm-not-available {
    min-height: 70vh;
  }
  

   @media screen and (max-width: 1080px) {
    .container-banyak .seni-card{
      width: 200px;
      height: 200px;
      /* background-color: brown; */
      margin: 5px;
      text-align: center;
      margin-bottom: 100px;
      text-decoration:none;
      border: white;
    }

    .container-banyak .nalar-card{
      width: 200px;
      height: 200px;
      /* background-color: brown; */
      margin: 5px;
      text-align: center;
      margin-bottom: 100px;
      text-decoration:none;
      border: white;
    }

    .container-banyak .olahraga-card{
      width: 200px;
      height: 200px;
      /* background-color: brown; */
      margin: 5px;
      text-align: center;
      margin-bottom: 100px;
      text-decoration:none;
      border: white;
    }
    
    .container-banyak{
      width: 80%;
      height: 100%;
      border: 0px solid black;
      margin: 20px auto;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    } 
  }