.btn-app {
  background-color: #FFAE00;
  color: white;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 40px;
}

.btn-app-secondary {
  background-color: var(--primary);
  color: white;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 40px;
}

.btn-app-secondary:hover {
  color: white;
}

.btn-kembali {
 background-color: white;
 border: 3px solid var(--primary);
 padding-left: 40px;
 padding-right: 40px;
 font-weight: 700;
}

.btn-kembali:hover {
  background-color: var(--primary);
  color: white;
}

.heading-app {
  font-weight: bold;
  color: #1E3D59;
}