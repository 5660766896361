/* .ukm-kategori-card {
  background-color: #1E3D59;
  border-radius: 15px;
  width: 250px;
  height: 250px;
  padding: 20px;
  display: flex;
}

.title-kategori{
  font-weight: bold;
  color: #1E3D59 ;
}

.ukm-kategori-img {
  position: relative;
  align-items: center;
}

html, body{
  margin: 0;
  height: 100%;
  position: relative;
}

.card-deck {
  height: 100%;
  justify-content: flex-center;
  display: flex;
} */




.container-tiga .kategori-card {
  width: 300px;
  height: 300px;
  /* height: 40vh; */
  background-color: #1E3D59;
  margin: 5px;
  padding: 50px;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 50px;
  text-decoration:none;

}

.container-tiga{
  width: 80%;
  height: 100%;
  border: 0px solid black;
  margin: 20px auto;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

}

/* .heading-app {
  font-weight: bold;
  color: #1E3D59;
}

.d-block{
  border: 30px solid #FFD987;
  background-color:#FFD987 ;
  color: #1E3D59;
  text-align: center;
  font-size:x-large;
  font-weight: bold;

} */

.title-kategori{
  font-weight: bold;
  color: #1E3D59 ;
}


@media screen and (max-width: 1080px) {

  .container-tiga .kategori-card {
    width: 200px;
    height: 200px;
    /* height: 40vh; */
    background-color: #1E3D59;
    margin: 5px;
    padding: 35px;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 20px;
    text-decoration:none;
  
  }
  
  .container-tiga{
    width: 80%;
    height: 100%;
    border: 0px solid black;
    margin: 20px auto;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  
  }
    

  
}