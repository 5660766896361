.ukm-image {
    border-radius: 12px;
    width: 100%;
    max-height: 25vw;
    margin: 0 30px;
    object-fit: cover;
}

.carousel-control-prev-icon, .carousel-control-next-icon  {
    background-color: black;
    border: 10px solid black;
    border-radius: 50%;
    padding: 15px;
}

.btn-kiri-gallery, .btn-kanan-gallery {
    position: absolute;
}

.btn-kiri-gallery {
    z-index: 999;
    top: 50%;
    left: -10%;
    transform: translate(0, -50%);
}

.btn-kanan-gallery {
    z-index: 999;
    top: 50%;
    right: -10%;
    transform: translate(0, -50%);
}