.ukm-sidebar {
    z-index: 999;
}

.ukm-detail-page {
    position: relative;
}

.content-margin {
    margin: 160px 0;
}

.ukm-sidebar {
    position: fixed;
    right: 30px;
}