.see-more-btn {
    cursor: pointer;
}

.rekomendasi-modal-header > .close {
    position: absolute;
    right: 10px;
    top: 10px;
}

.rekomendasi-modal > .modal-dialog {
    max-width: 1000px;
}