.formulirSaran .captionJudul, 
               .judulForm {
    color: #1e3d59 ;
    text-align: center;
    font-weight: bold;
}

.formulirSaran .subCaption {
    color: #1e3d59 ;
    text-align: center;
}

.formulirSaran {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.kirimSaran {
    /* margin-left: 24rem; */
}

.formInputSaran .formSaran {
    border: 3px solid #1e3d59;
}