.saran-banner {
    background-color: var(--primary);
    position: relative;
}

.text-saran {
    color: white;
}

.decor-kiri, .decor-kanan {
    background-color: var(--secondary);
}

.saran-text-content {
    padding: 120px 0 50px;
}

.decor-kiri {
    width: 500px;
    height: 100px;
    border-top-right-radius: 100%;
}

.decor-kanan {
    width: 300px;
    height: 100px;
    border-top-left-radius: 100%;
}

@media (max-width: 900px) {
    .decor-kiri {
        border-top-right-radius: 0;
        border: 1px solid var(--secondary);
        width: 100%;
    }

    .decor-kanan {
        border-top-left-radius: 0;
        border: 1px solid var(--secondary);
        width: 100%;
    }
}