.result-box {
     height: 100%;
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-evenly;
}

.result-box .listSearch {
    height: 300px;
    width: 250px;
    background-color: #1E3D59;
    color: white;
    text-decoration:none;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    margin-top: 5vh;
}

.listSearch .nama {
    padding-top: 10px;
    font-weight: 500;
}

.listSearch:hover {
    transform: translateY(-5px);
    transition: 0.3s;
    background-color: #204463;
}