.ukm-sidebar-link {
    margin: 15px 0;
    color: #ffa500;
    font-weight: bold;
}

.ukm-sidebar input[type='radio'] {
    position: relative;
    margin-left: 0;
}

.ukm-sidebar .form-check-label {
    margin-right: 5px;
}

.ukm-sidebar .form-check {
    display: flex;
    align-items: center;
}

.ukm-sidebar input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -5px;
    left: -1px;
    position: relative;
    background-color: #1E3D59;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.ukm-sidebar input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -5px;
    left: -4px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.ukm-sidebar input[type='radio']:hover:after {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -5px;
    left: -4px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.form-check-label {
}

.ukm-sidebar input[type='radio']:hover + .form-check-label {
    color: white;
}