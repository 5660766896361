.footer-body {
    background-color: var(--primary);
    color: white;
}

.icons img {
    width: 35px;
    height: 35px;
}

.footer-divider {
    height: 40px;
    background-color: var(--secondary);
}

.footer-logo {
    object-fit: cover;
}

.contact-list {
    padding-left: 0;
    list-style-type: none;
}

.contact-list > li {
    margin: 12px 0;
}