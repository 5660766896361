.daftar{
	background-color: #1E3D59;
	color: white;
	padding: 50px 0;
}

.tombol{
	background-color: #FFAE00;
	color: white;
	border-radius: 5px;
    margin-top: 10px;
}
.font{
    font-size: 24px;
}