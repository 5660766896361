.ukm-info {
    border-left: 3px solid #1E3D59;
    position: relative;
}

.info-circle {
    position: absolute;
    background-color: #FF6E40;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    left: -14px;
}

.ukm-info *:not(.heading-app) {
    font-size: 16px;
}