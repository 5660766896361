.ukm-prestasi-card {
    border: 0;
    width: 18rem;
}

.ukm-prestasi-card .card-title {
    font-weight: bold;
}

.ukm-prestasi-img {
    position: relative;
}

.ukm-prestasi-posisi {
    position: absolute;
    font-weight: bold;
    color: white;
}