.rekomendasi-body {
    border: 3px solid var(--primary);
    border-radius: 20px;
    max-width: 1000px;
}

.rekomendasi-heading {
    font-weight: 700;
    color: var(--primary);
}

.rekomendasi-subheading {
    color: var(--primary);  
}

.rekomendasi-tag {
    border-radius: 5px;
    background-color: var(--secondary);
    color: white;
    font-weight: 600;
}

.rekomendasi-tag:hover {
    cursor: pointer;
    background-color: #FFC13B;
}

.rekomendasi-tag-active {
    position: relative;
    border-radius: 5px;
    background-color: #FFC13B;
    color: white;
    font-weight: 600;
}

.btn-control {
    width: 60px;
    object-fit: contain;
}

.tag-disable {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    font-weight: bold;
    color: black;
}

.img-rekomendasi {
    object-fit: cover;
    height: 250px;
    width: 18rem;
}

.ukm-rekomendasi-hasil {
    position: relative;
}

.rekomendasi-link {
    color: white;
    text-decoration: none;
    background-color: var(--primary);
    position: absolute;
    transform: translateY(100%);
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
}

.ukm-rekomendasi-hasil:hover > .rekomendasi-link {
    transition: 0.5s;
    transform: translateY(0%);
}

.btn-kiri, .btn-kanan {
    position: absolute;
}

.btn-kiri {
    z-index: 999;
    top: 50%;
    left: 3vw;
    transform: translateY(-50%);
}

.btn-kanan {
    z-index: 999;
    top: 50%;
    right: 3vw;
    transform: translateY(-50%);
}

.rekomendasi-link:hover {
    color: white;
    text-decoration: none;
}

.nav-bottom {
    display: none;
}

@media (max-width: 1200px) {
    .btn-kiri {
        left: 1vw;
    }

    .btn-kanan {
        right: 1vw;
    }
}

@media (max-width: 1100px) {
    .btn-kiri, .btn-kanan {
        display: none;
    }

    .btn-kanan-res {
        /* transform: translateX(50%); */
        width: 100%;
    }

    .btn-kiri-res {
        /* transform: translateX(50%); */
        width: 100%;
    }

    .nav-bottom {
        /* z-index: 9998; */
        display: flex;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: var(--primary);
    }
}

@media (max-width: 768px) {
    .nav-bottom {
        bottom: -50px;
    }
}