.bg-navbar {
    background-color: #1e3d59 ;
    font-family: 'Poppins';
    font-weight: 600;
}

.rightSide .menuNavbar .nav-link {
    color: #fff;
}

.menuNavbar > * {
    margin-left: 1.5vw!important;
    margin-right: 1.5vw!important;
}


.menuNavbar .nav-link, .menuNavbar .nav-item {
    letter-spacing: 3px;
}

.rightSide .menuNavbar .nav-link:hover, .rightSide .menuNavbar .nav-link:focus {
    color: rgb(226, 226, 226);
}

.navbarClass .leftSide {
    display: flex;
    justify-content: left;
    align-items: center;
}

.navbarClass .rightSide {
    flex: 50%;
    justify-content: flex-end;
    align-items: center;
}

.leftMenuNavbar {
    justify-content: flex-end;
}

.menuDropdown-item {
    transition: .3s;
}

.menuDropdown-item:hover {
    background-color: #1e3d59 ;
    color: #fff;
}

a:hover {
    text-decoration: none;
}

.navbarClass .navbarCollapse {
    justify-content: flex-end;
}

.navbarClass .searchBar {
    display: flex;
}

.searchInput {
    padding: 0px 10px;
    border-radius: 15px;
    height: 42px;
    width: 100%;
}

.searchInput:focus {
    outline: none;
}

.searchButton {
    background-color: #FFAE00;
    border-color: #FFAE00;
    border-radius: 15px;
    padding: 0px 10px;
}

.searchBar .searchButton:hover {
    background-color: rgb(224, 153, 0);
    border-color: rgb(224, 153, 0);
}

.material-icons {
    display: inline-block;
    text-transform: none;
    vertical-align: middle;
    color: #fff;
}

.menuDropdown .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #ffff;
}

.menuDropdown:hover .dropdown-menu {
    display: block;
}

.menuDropdown {
    transition: .3s;
}