.formulirPendaftaran .judul {
    color: #1e3d59 ;
    text-align: center;
    font-family: 'Poppins';
    font-weight: bold;
}

.formulirPendaftaran .form-label {
    color: #1e3d59 ;
    font-weight: 600;
}

.fillForm {
    border: 3px solid #1e3d59;
}

.daftarukm {
    margin: 2em 3em 8em 0em;
}